import React from "react"
import {
  Box,
  ContentBlock,
  Columns,
  Column,
  Text,
  Stack,
} from "@64labs/bowline-design-system"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from "../../components/contact/sidebar"

const Thanks = () => {
  return (
    <Layout showPageHeader pageHeaderText="Contact Us">
      <SEO
        title="Contact Us"
        description="Get in touch with 64labs to discuss your mobile and web challenges."
      />
      <Box paddingY={["gutter", "gutter", "large"]}>
        <ContentBlock>
          <Columns cols={[1, 1, 4]}>
            <Column span={[1, 1, 3]}>
              <Stack space="small">
                <Text size="xlarge" tone="brandAccent" heading>
                  Success (our favorite word)
                </Text>
                <Text>
                  Thank you for reaching out to 64labs. A member of our team
                  will get in touch within 48hrs. If your project is time
                  sensitive, call our client hotline on{" "}
                  <Text
                    baseline={false}
                    tone="brandAccent"
                    display="inline"
                    as="a"
                    href="tel:1-813-495-5275"
                  >
                    (813) 495-5275
                  </Text>
                  . <b>We look forward to working with you.</b>
                </Text>
              </Stack>
            </Column>
            <Sidebar />
          </Columns>
        </ContentBlock>
      </Box>
    </Layout>
  )
}

export default Thanks
